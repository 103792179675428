(function($){

  var emblem = '';
  var answer = '';
  var secret_value;

  var matchmain = '';

  var mdmuapp = {

    init: function(){

      mdmuapp.triggers();

      if($('.smartlabel').length){
        mdmuapp.validateForm();
      }
      if($('#person-form').length){
        mdmuapp.submitPerson();
        mdmuapp.submitAnswers();
      }
      if($('.range-dot').length){
        mdmuapp.rangeDots();
      }
      if($('.freq-select').length){
        mdmuapp.frequencyDots();
      }
      if($('.multi-select').length){
        mdmuapp.multiSelect();
      }
      if($('.smart-venn-area').length){
        mdmuapp.dragVennDot();
        mdmuapp.dragVennTwoDot();
      }
      if($('#filters').length){
        mdmuapp.filterControls();
        mdmuapp.providerClick();
      }
      if($('.results-zip').length){
        mdmuapp.zipSlider();
      }
      if($('#refresh-pagination').length){
        mdmuapp.refreshPagination();
        mdmuapp.applyFiltersDedicated();
      }
      if($('.send-matches-trigger').length){
        mdmuapp.sendMatches();
      }
      if($('#return-my-matches').length){
        mdmuapp.reloadMatches();
      }

      // if($('.load-for-john').length){
      //   mdmuapp.testJohn();
      // }

    },
    // testJohn: function(){

    //   $('.load-for-john').on('click', function(){
    //     $('input[name="active_zip_code').val('57401');
    //     $('#results-zip').data('persona', 'au');
    //     $('#mdmwrap').removeClass('survey-focus').addClass('results-focus');

    //     $('.load-for-john').remove();

    //     $.post('/geo?john=test',function(data){ });
    //   });

    // },
    sendMatches: function(){

      $('.send-matches-trigger').on('click', function(){
        var userzip = $('input[name="active_zip_code"]').val();
        var userzipradius = $('input[name="active_zip_radius"]').val();
        var emblem = $('#slides').data('emblem');
        var persona = $('#results-zip').data('persona');

        var active_filters = '';
        $('.filter-set-option.active').each(function(){
          if($(this).hasClass('experience')){
            var new_filter = $(this).data('value');
          } else {
            var new_filter = $(this).data('name');
          }
          active_filters += ',' + new_filter
        });

        // add array of first 3 visible
        var pro_first = $('.provider.matched:eq(0)').data('i');
        var pro_second = $('.provider.matched:eq(1)').data('i');
        var pro_third = $('.provider.matched:eq(2)').data('i');

        var provs = '';
        if (pro_first != undefined){ provs = pro_first; }
        if (pro_second != undefined){ provs += ',' +pro_second; }
        if (pro_third != undefined){ provs += ',' +pro_third; }

        $.post('/send-matches?e=' +emblem +'&p='+persona +'&z='+userzip +'&r='+userzipradius +'&f='+active_filters +'&prov='+provs,function(data){ });
      });

      $('#send-matches-confirm').on('click', '#marker-request .submit-button', function(){
        var thisform = $('form#marker-request');
        $.ajax({
          url: '/confirm-send-matches',
          type: 'POST',
          data: thisform.serialize()
        });
      });

      $('#send-matches-confirm').on('click', '.request-match-shade, .request-match-close', function(){
        $('#send-matches-confirm').html('');
        $('body').removeClass('send-matches-open');        
      });

    },
    reloadMatches: function(){

      $('form#return-my-matches .submit-button').on('click', function(){
        var thisform = $('form#return-my-matches');
        $.ajax({
          url: '/reload-matches',
          type: 'POST',
          data: thisform.serialize()
        });        
      });

    },
    lazyProfilePics: function(){
      // focus just on loading for .current
      if($('.currentpage').length){
        $('.providers-wrap').removeClass('no-matches');
        $('.currentpage').each(function(){
          var thisprovider = $(this);
          var thisimg = $('img.lazy',thisprovider);
          var geturl = thisimg.attr('data-imgsrc');

          thisimg.attr("src",geturl);
          setTimeout(function(){
            thisprovider.addClass('scalein');
          },100);
        });
      } else {
        $('.providers-wrap').addClass('no-matches');
      }

    },
    initialPagination: function(){
      // render on new set
      // add current to first 8 .matched
      for (i = 0; i < 8; i++) {
        $('.matched:eq(' + i + ')').addClass('currentpage');
      }
      mdmuapp.lazyProfilePics();

      // add best-match to first three
      for (i = 0; i < 3; i++) {
        $('.matched:eq(' + i + ')').addClass('best-match');
      }
    },
    nextPagination: function(){
      // reset the navigation
      $('#next-page').removeClass('usable').html('');

      // create pagination navs
      matchedcount = $('.matched').length;

      // check if pagination needed
      if(matchedcount > 8){
        $('#next-page').addClass('usable');
        // calculate number of pages
        var pagecount = matchedcount / 8;
        var renderpagecount = Math.ceil(pagecount);
        // render html
        for (i = 0; i < renderpagecount; i++) {
          $('#next-page').append('<span class="pagenum"></span>');
        }
        // set the first as active
        $('.pagenum:first-of-type').addClass('currentnav');
      }
      for (i = 0; i < 8; i++) {
        $('.matched:eq(' + i + ')').addClass('currentpage');
      }
      // load the set of pics
      setTimeout(function(){
        mdmuapp.lazyProfilePics();
      },500);


      // listen for clicks
      $('#next-page').on('click', '.pagenum', function(){
        var clickedpage = $(this);

        var currentposition = $('.pagenum.current').index();
        var clickedposition = clickedpage.index();

        if(currentposition == clickedposition){
          // do nothing
        } else {
          showProvidersOnPage(clickedposition);
          clickedpage.addClass('currentnav').siblings().removeClass('currentnav');
        }
      });

      // render the new page
      function showProvidersOnPage(clickedpage){
        // remove .visible from all
        // get the index of new first visible
          // is the index of clickedpage * 8
        // add visible to this and next 7 .matched.zipped
        $('.matched').removeClass('currentpage');
        var newpagestartnum = clickedpage * 8;

        for (i = 0; i < 8; i++) {
          $('.matched:eq(' + (newpagestartnum + i) + ')').addClass('currentpage');
        }

        setTimeout(function(){
          mdmuapp.lazyProfilePics();
        },500);
      }

    },
    smartVennEvaluate: function(){

      var thisslide = $('.slide.active');

      var dot_p = $('.smart-venn-dot', thisslide).position();
      var circle_one_p = $('.svc-one', thisslide).position();
      var circle_two_p = $('.svc-two', thisslide).position();
      var circle_three_p = $('.svc-three', thisslide).position();

      var dot_x = dot_p.left + 11;
      var dot_y = dot_p.top + 11;
      var center_one_x = circle_one_p.left + 75;
      var center_one_y = circle_one_p.top + 75;
      var center_two_x = circle_two_p.left + 75;
      var center_two_y = circle_two_p.top + 75;
      var center_three_x = circle_three_p.left + 75;
      var center_three_y = circle_three_p.top + 75;
      var radius = 75;
      var circle_r = radius^2;

      var is_one = check_a_point(dot_x, dot_y, center_one_x, center_one_y, circle_r);
      var is_two = check_a_point(dot_x, dot_y, center_two_x, center_two_y, circle_r);
      var is_three = check_a_point(dot_x, dot_y, center_three_x, center_three_y, circle_r);


      $('.slide-task',thisslide).removeClass('error');

      if( is_one == true && is_two == false && is_three == false ){
        if(dot_y < (center_one_y - 20)){
          $('.smart-venn-area', thisslide).data('area','8');
        } else {
          $('.smart-venn-area', thisslide).data('area','5');
        }
      } else if( is_one == true && is_two == true && is_three == false ){
        $('.smart-venn-area', thisslide).data('area','2');
      } else if( is_one == true && is_two == false && is_three == true ){
        $('.smart-venn-area', thisslide).data('area','4');
      } else if( is_one == true && is_two == true && is_three == true ){
        $('.smart-venn-area', thisslide).data('area','1');
      } else if( is_one == false && is_two == true && is_three == false ){
        if(dot_x < (center_two_x - 15)){
          $('.smart-venn-area', thisslide).data('area','9');
        } else {
          $('.smart-venn-area', thisslide).data('area','6');
        }
      } else if( is_one == false && is_two == true && is_three == true ){
        $('.smart-venn-area', thisslide).data('area','3');
      } else if( is_one == false && is_two == false && is_three == true ){
        if(dot_x > (center_three_x + 15)){
          $('.smart-venn-area', thisslide).data('area','10');
        } else {
          $('.smart-venn-area', thisslide).data('area','7');
        }
      } else {
        $('.slide-task', thisslide).addClass('error');
      }

      function check_a_point(a, b, x, y, r) {
        var dist_points = (a - x) * (a - x) + (b - y) * (b - y);
        r *= r;

        if (dist_points < r) {
          return true;
        }
        return false;
      }

    },
    dragVennDot: function(){

      var dragItem = document.querySelector("#smart-venn-one-area .smart-venn-dot");
      var container = document.querySelector("#smart-venn-one-area");

      var active = false;
      var currentX;
      var currentY;
      var initialX;
      var initialY;
      var xOffset = 0;
      var yOffset = 0;

      container.addEventListener("touchstart", dragStart, false);
      container.addEventListener("touchend", dragEnd, false);
      container.addEventListener("touchmove", drag, false);

      container.addEventListener("mousedown", dragStart, false);
      container.addEventListener("mouseup", dragEnd, false);
      container.addEventListener("mousemove", drag, false);

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches[0].clientX - xOffset;
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }

        if (e.target === dragItem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        initialY = currentY;

        active = false;

        mdmuapp.smartVennEvaluate();
      }

      function drag(e) {
        if (active) {

          e.preventDefault();

          if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, dragItem);
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      }

      $('.slide-dot-reset').on('click', function(){
        var thistask = $(this).closest('.slide-task');
        $('.smart-venn-dot',thistask).removeAttr("style");

        mdmuapp.smartVennEvaluate();

        xOffset = 0;
        yOffset = 0;

      });

    },
    dragVennTwoDot: function(){

      var dragItem = document.querySelector("#smart-venn-two-area .smart-venn-dot");
      var container = document.querySelector("#smart-venn-two-area");

      var active = false;
      var currentX;
      var currentY;
      var initialX;
      var initialY;
      var xOffset = 0;
      var yOffset = 0;

      container.addEventListener("touchstart", dragStart, false);
      container.addEventListener("touchend", dragEnd, false);
      container.addEventListener("touchmove", drag, false);

      container.addEventListener("mousedown", dragStart, false);
      container.addEventListener("mouseup", dragEnd, false);
      container.addEventListener("mousemove", drag, false);

      function dragStart(e) {
        if (e.type === "touchstart") {
          initialX = e.touches[0].clientX - xOffset;
          initialY = e.touches[0].clientY - yOffset;
        } else {
          initialX = e.clientX - xOffset;
          initialY = e.clientY - yOffset;
        }

        if (e.target === dragItem) {
          active = true;
        }
      }

      function dragEnd(e) {
        initialX = currentX;
        initialY = currentY;

        active = false;

        mdmuapp.smartVennEvaluate();
      }

      function drag(e) {
        if (active) {

          e.preventDefault();

          if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
          } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          }

          xOffset = currentX;
          yOffset = currentY;

          setTranslate(currentX, currentY, dragItem);
        }
      }

      function setTranslate(xPos, yPos, el) {
        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
      }

      $('.slide-dot-reset').on('click', function(){
        var thistask = $(this).closest('.slide-task');
        $('.smart-venn-dot',thistask).removeAttr("style");
        thistask.removeClass('error');

        mdmuapp.smartVennEvaluate();

        xOffset = 0;
        yOffset = 0;

      });

    },
    filterControls: function(){
      // open / close filters
      $('.filter-set-name').on('click', function(){
        $(this).closest('.filter-set').addClass('active');
        $('body').addClass('filter-open');
        // then run apply filters ?
      });

      $('.filter-set-option').on('click', function(){
        var thisoption = $(this);
        var thisset = thisoption.closest('.filter-set-options');

        if(thisset.hasClass('plural')){
          thisoption.toggleClass('active');
        } else if(thisset.hasClass('singular')){
          thisoption.toggleClass('active');
          thisoption.siblings().removeClass('active');
        } else if(thisset.hasClass('greater-than')){
          thisoption.toggleClass('active');
          thisoption.siblings().removeClass('active');
        } else {
          // do nothing
        }

        // if parent is plural
          // toggle active class
          // if this or siblings have active class
            // apply in-use class to closest .filter-set
        // else if parent is single || greater than
          // toggle active class
          // remove active from siblings
          // if this or siblings have active class
            // apply in-use class to closest .filter-set

      });

      $('.filter-set-shade').on('click', function(){
        $(this).closest('.filter-set').removeClass('active');
        $('body').removeClass('filter-open');
      });

      $('.filter-set-apply').on('click', function(){
        $(this).closest('.filter-set').removeClass('active');
        $('body').removeClass('filter-open');
        mdmuapp.applyFilters();
      });

      // reset for clear filter or no-match button
      $('#mdmwrap').on('click', '.filter-clear-apply, .no-match-reset', function(){
        $('.filter-set-option').removeClass('active');
        mdmuapp.applyFilters();
      });


    },
    applyFilters: function(){
      // reset prematches
      $('.provider').removeClass('prematch');
      // reset indicators
      $('.filter-set').removeClass('in-use');
      // reset catches no filters selected
      if($('.filter-set-option.active').length){
        $('.provider').removeClass('matched currentpage');
      } else {
        $('.provider').addClass('matched').removeClass('currentpage');
      }

      // handle no specialty applied same as all applied
      if($('.filter-set-option.specialty.active').length == 0){
        $('.provider').addClass('prematch');
      }

      // filters run expansive then reductive
      matchmain = '';
      $('.filter-set-option.active').each(function(){
        if($(this).hasClass('experience')){
          // skip here; runs on own logic below

        } else if($(this).hasClass('specialty')){ 
          // expansive filter
          var thismatch = $(this).attr('data-name');
          var thismatchclass = '.' + thismatch;
          // immediately apply matching
          $('.provider.' + thismatch).addClass('prematch');

          // expansive filters excluded from matchmain
          // matchmain += thismatchclass;

          // indicate filter in use
          $(this).closest('.filter-set').addClass('in-use');

        } else { 
          // reductive filters
          var thismatch = $(this).attr('data-name');
          var thismatchclass = '.' + thismatch;
          // build string for matching
          matchmain += thismatchclass;

          // indicate filter in use
          $(this).closest('.filter-set').addClass('in-use');
        }
      });

      // apply reductive filters
      if(matchmain != ''){
        $('.provider.prematch' + matchmain).addClass('matched');
      } else {
        $('.provider.prematch').addClass('matched');
      }

      // further reductive (experience calculation as reduction)
      // if N else x
      // if not within range then remove .matched
      if($('.filter-set-option.experience.active').length){
        $('.filter-set-options.greater-than').closest('.filter-set').addClass('in-use');

        var experiencefilter = $('.filter-set-option.experience.active').attr('data-value');
        if(experiencefilter == 'N'){
          $('.provider.matched').each(function(){
            var thisexperience = $(this).attr('data-years');
            var thismatchclass = '.yrs-' + thisexperience;
            if(parseInt(thisexperience) > 2){
              $(this).removeClass('matched');
            }
            
            // build string for matching
            matchmain += thismatchclass;            
          });
        } else {
          $('.provider.matched').each(function(){
            var thisexperience = $(this).attr('data-years');
            if(parseInt(thisexperience) < parseInt(experiencefilter)){
              $(this).removeClass('matched');
            }
          });
        }
      }

      var emblem = $('#slides').data('emblem');
      $.post('/fltrs?f='+matchmain +'&e='+emblem,function(data){ });

      setTimeout(function() {
        mdmuapp.nextPagination();
      },1000);

    },
    providerClick: function(){

      $('#results-complete').on('click', 'a.provider-click', function(){
        var emblem = $('#slides').data('emblem');
        var provider = $(this).closest('.provider').data('i');
        $.post('/prvdr?p='+provider +'&e='+emblem +'&f='+matchmain,function(data){ });
      });

    },
    validateForm: function(){

      $('.smartlabel input').focus(function(){
        $(this).closest('.smartlabel').addClass('focused').removeClass('dormant');
      });

      $('.person-form .required input').blur(function(){
        var validatethis = $(this);
        var thisvalue = validatethis.val();

        if(thisvalue == ''){
          validatethis.closest('.fieldset').addClass('show-error');
        } else {
          validatethis.closest('.fieldset').removeClass('show-error');
        }

        // also if field is email
          // check is valid?
          // if no, add error
        if($(validatethis).hasClass('email_valid')){
          thisemailval = validatethis.val();
          if(isValidEmail(thisemailval)==false){
            validatethis.closest('.fieldset').addClass('show-error');
          } else {
            validatethis.closest('.fieldset').removeClass('show-error');
          }
        }

      });


      function isValidEmail(thisemail) {
        var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!regex.test(thisemail)) {
          return false;
        } else {
          return true;
        }
      }

    },
    multiSelect: function(){

      $('.multi-select').on('click', function(){
        $(this).toggleClass('selected');
      });

    },
    frequencyDots: function(){

      $('.freq-select').on('click', function(){
        $(this).addClass('active').siblings().removeClass('active');
        $(this).closest('.freq-selects-set').addClass('live');
      });

    },
    rangeDots: function(){

      $('.range-dot').on('click', function(){
        $(this).addClass('active').siblings().removeClass('active');
      });

    },
    submitPerson: function(){

      $('#person-form .submit-button').on('click', function(){
        if($('.show-error').length || $('.dormant').length){
          alert('Please complete required fields');
        } else {
          var f_name = $('input#first_name').val();
          var l_name = $('input#last_name').val();
          var e_address = $('input#email_address').val();
          var z_code = $('input#zip_code').val();
          var b_year = $('input#birth_year').val();

          var provider_preference = '';
          if(document.getElementById('primary_care').checked){
            var prefer_primary = true;
            provider_preference += 'primary_care ';
          } else { var prefer_primary = false; }
          if(document.getElementById('womens_health').checked){
            var prefer_womens_health = true;
            provider_preference += 'womens_health ';
          } else { var prefer_womens_health = false; }
          if(document.getElementById('pediatrics').checked){
            var prefer_pediatrics = true;
            provider_preference += 'pediatrics';
          } else { var prefer_pediatrics = false; }


          // refactor for serialize and submit
          $.post('/indt?f='+f_name +'&l='+l_name +'&e='+e_address +'&z='+z_code +'&b='+b_year +'&pri='+prefer_primary +'&wom='+prefer_womens_health +'&ped='+prefer_pediatrics,function(data){ });

          $('input[name="active_zip_code"]').val(z_code);
          $('#filters').data('preset-preference',provider_preference);
        }
      });

    },
    submitAnswers: function(){

      $('.slide-advance').on('click', function(){

        var emblem = $(this).closest('#slides').data('emblem');

        var this_q = $(this).closest('.slide');
        var q_no = this_q.data('number');
        var q_answer;
        var zip = $('#results-zip').html();

        if(q_no == 'q1' || q_no == 'q2'){
          q_answer = $('.smart-venn-area', this_q).data('area');

        } else if(q_no == 'q3' || q_no == 'q4' || q_no == 'q5' || q_no == 'q6' || q_no == 'q7'){
          q_answer = $('input[type=range]', this_q).val();

        } else if(q_no == 'q8' || q_no == 'q9'){
          var answerstring = '';
          $('.multi-select.selected', this_q).each(function(){
            var selectedanswer = $(this).data('answer');
            answerstring += selectedanswer + ', ';
            q_answer = answerstring;
          });

        } else if(q_no == 'q10'){
          // show confirm message
          $('.confirm-message-wrap').addClass('active');
          // send answers within .agree-button click

        } else {
          // nothing
        }
        // send answers, unless q10
        if(q_no != 'q10'){
          $.post('/cnct?e='+emblem+'&q='+q_no+'&a='+q_answer+'&z='+zip,function(data){ });
        }


        // if(q_no == 'q1 - q2 ') -› selected area value
        // if(q_no == 'q3 - q7') -› selected area value
        // if(q_no == 'q8 - q9') -› array .multi-select
        // if(q_no == 'q10') -› array count of daily, occasionally, never

      });

      // q10 confirm advance
      $('.agree-button').on('click', function(){
        if($(this).hasClass('confirm')){
          var optin = 'confirm';
        } else {
          var optin = 'decline';
        }

          var emblem = $(this).closest('#slides').data('emblem');
          var this_q = $('.slide[data-number="q10"]');
          var zip = $('input[name="active_zip_code"]').val();
          var q_answer;

          var answerstring = '';
          $('.freq-select.active', this_q).each(function(){
            var selectedanswer = $(this).data('value');
            answerstring += selectedanswer + ', ';
            q_answer = answerstring;
          });

          $.post('/cnct?e='+emblem +'&q=q10' +'&a='+q_answer +'&z='+zip  +'&confirm='+optin,function(data){ });
        $('.confirm-message-wrap').removeClass('active');
      });

      // for reviewing answers
      $('.slide-retreat').on('click', function(){
        currentslide = $(this).closest('.slide');
        currentslide.removeClass('active').prev().addClass('active');
      });


    },
    zipSlider: function(){

      $('input[name="active_zip_radius"]').change(function(){
        // get new value
        // pass to controller
        var persona = $('#results-zip').data('persona');
        var userzip = $('input[name="active_zip_code"]').val();
        var userzipradius = $('input[name="active_zip_radius"]').val();

        $.post('/geo?z='+userzip+'&r='+userzipradius+'&p='+persona,function(data){ });
      });

      $('input[name="active_zip_code"]').keyup(function(){
        // check if not a number
        // check if number between 1000 - 99999
          // true, pass to controller
          // false, add .error to input

        var userzip = $('input[name="active_zip_code"]').val();
        if(isNaN(userzip)){
          $('input[name="active_zip_code"]').addClass('error');

        } else {
          if( userzip > 9999 && userzip < 99999 ){
            var persona = $('#results-zip').data('persona');
            var userzipradius = $('input[name="active_zip_radius"]').val();

            $('input[name="active_zip_code"]').removeClass('error');
            $.post('/geo?z='+userzip+'&r='+userzipradius+'&p='+persona,function(data){ });

          } else {
            $('input[name="active_zip_code"]').addClass('error');
          }
        }

      });

    },
    refreshPagination: function(){

      $('#refresh-pagination').on('click', function(){
        mdmuapp.initialPagination();
        mdmuapp.nextPagination();
      });

    },
    applyFiltersDedicated: function(){
      $('#standalone-filter-trigger').on('click', function(){
        mdmuapp.applyFilters();
      });
    },
    restoreFilters: function(){

      var stored_filters = $('#continue').data('filters');
      if(stored_filters){
        let filters = stored_filters.split(',');
        for (const filter of filters){
          if(filter != ''){
            $('.filter-set-option[data-name="' +filter+ '"]').addClass('active');
          }
        };
      }

    },
    triggers: function(){
    
      $('#continue').on('click', '.marker-resume', function(){
        mdmuapp.filterControls();
        mdmuapp.providerClick();
        mdmuapp.zipSlider();
        mdmuapp.refreshPagination();
        mdmuapp.applyFiltersDedicated();
        mdmuapp.sendMatches();
        mdmuapp.restoreFilters();

        setTimeout(function(){
          mdmuapp.applyFilters();
          mdmuapp.initialPagination();
          mdmuapp.nextPagination();
        },500);
      });    
    }

  };


  $(window).on('load', function(){

    mdmuapp.init();

  });

  $(window).on('resize', function() {

  });


})(window.jQuery);
